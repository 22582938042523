<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>权限设置</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <a-page-header :title="`${title}端权限管理`">
      <a-descriptions size="small" :column="3">
        <a-descriptions-item>授权角色名称：{{ roleName }}</a-descriptions-item>
      </a-descriptions>
    </a-page-header>
    <a-layout-content class="content-container">
      <a-row>
        <a-col :span="10">
          <!-- 可授权权限树 -->
          <a-card :title="`${title}端菜单权限`">
            <a-tree
              :defaultExpandAll="true"
              :expanded-keys="expandedKeys"
              checkable
              v-model="checkedKeys"
              :tree-data="avaFuncTreeData"
              :replaceFields="menuTreeFields"
              @expand="onExpand"
              @check="onCheck"
            />
          </a-card>
        </a-col>
        <a-col :span="10" :offset="1">
          <a-card title="已授权菜单">
            <!-- 已授权权限树 -->
            <a-tree
              :defaultExpandAll="true"
              :expanded-keys="expandedAuthorizedKeys"
              @expand="onExpandAuthorized"
              showLine
              :tree-data="authorizedFuncData"
              :replaceFields="menuTreeFields"
            />
            <a-empty v-if="!hasAuthorizedFunc" />
          </a-card>
        </a-col>
      </a-row>
      <a-row class="pt-10">
        <a-col :span="8">
          <a-space size="large">
            <a-button type="primary" @click="onSave">授权</a-button>
            <a-button @click="onCancel">取消</a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { getPrivilegeList, editPrivileges } from "@/services/ValueBag";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
const menuTreeFields = {
  children: "subMenus",
  title: "name",
  key: "menuId",
  value: "menuId"
};
export default {
  name: "setPrivilege",
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      expandedKeys: [],
      expandedAuthorizedKeys: [],
      checkedKeys: [],
      avaFuncTreeData: [],
      menuTreeFields,
      authorizedFuncData: [],
      roleId: this.$route.query.id || "", // 角色id
      roleName: this.$route.query.name || "" // 授权角色名称
    };
  },
  computed: {
    // 页面标题
    title() {
      return this.$route.query.type === "web" ? "Web" : "App";
    },
    // 是否有已授权数据
    hasAuthorizedFunc() {
      return (
        this.authorizedFuncData !== undefined &&
        this.authorizedFuncData.length > 0
      );
    }
  },
  mounted() {
    this.getPrivilegeList();
  },
  methods: {
    // 获取用户权限列表
    async getPrivilegeList() {
      if (this.roleId) {
        const { data } = await getPrivilegeList({
          roleId: this.roleId,
          terminalType: this.$route.query.type
        });
        this.avaFuncTreeData = data.data.avaFuncTreeData;
        this.authorizedFuncData = data.data.authorizedFuncTreeData;
        this.checkedKeys = data.data.authorizedFuncIds;
        // 所有权限
        this.expandedKeys = this.avaFuncTreeData.reduce(
          (keys, { menuId, subMenus }) => {
            if (subMenus && subMenus.length) {
              keys.push(menuId);
              keys.push(...this.getDescendantKeys(subMenus));
            }
            return keys;
          },
          []
        );
        // 已授权权限
        this.expandedAuthorizedKeys = this.authorizedFuncData.reduce(
          (keys, { menuId, subMenus }) => {
            if (subMenus && subMenus.length) {
              keys.push(menuId);
              keys.push(...this.getDescendantKeys(subMenus));
            }
            return keys;
          },
          []
        );
      } else {
        this.$message.error("出错了");
      }
    },
    // 选中权限时触发
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys;
    },
    // 确认授权
    async onSave() {
      let authParams = {
        funcIdList: this.checkedKeys,
        terminalType: this.$route.query.type
      };
      await editPrivileges(this.roleId, authParams);
      this.$message.success("授权成功");
      this.onCancel();
    },
    // 取消
    onCancel() {
      this.$router.back();
    },
    // 递归遍历获取所有节点
    getDescendantKeys(subMenus) {
      return subMenus.reduce((keys, { menuId, subMenus }) => {
        if (subMenus && subMenus.length) {
          keys.push(menuId);
          keys.push(...this.getDescendantKeys(subMenus));
        }
        return keys;
      }, []);
    },
    // 权限树展开/折叠触发
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
    },
    onExpandAuthorized(expandedKeys) {
      this.expandedAuthorizedKeys = expandedKeys;
    }
  }
};
</script>

<style scoped>
.pt-10 {
  padding-top: 10px;
}
.content-container {
  padding: 20px;
}
/deep/ .ant-page-header {
  padding: 10px;
  margin: 16px 24px;
  background-color: #fff;
}
</style>
